<template>
  <div class="main">
    <h1>{{ title }}</h1>
    <label>
      Go Spunky
      <input type="checkbox" v-model="checked">
    </label>
    <div v-if="checked === false">
      <span>{{ description }}</span>
      <div class="btn-area">
        <button class="btn-left" @click.prevent="countDown">-</button>
        <div class="number" @click.prevent="generateChords">
          {{ number }}
        </div>
        <button class="btn-right" @click.prevent="add">+</button>
      </div>
    </div>
    <div v-else>
      <div class="btn-area">
        <div class="number" @click.prevent="spunky">
          Go
        </div>
      </div>
    </div>
    <div class="chords-area">
      <div class="verse">
        <span>verse</span>
        <div class="chords">
          <div class="chord" v-for="(c, i) in verseChords" :key="i">
            {{ c }}
          </div>
        </div>
      </div>
      <div class="chorus">
        <span>chorus</span>
        <div class="chords">
          <div class="chord" v-for="(c, i) in chorusChords" :key="i">
            {{ c }}
          </div>
        </div>
      </div>
      <div class="bridge">
        <span>bridge</span>
        <div class="chords">
          <div class="chord" v-for="(c, i) in bridgeChords" :key="i">
            {{ c }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
function splitInThree(startValue) {
  //console.log(startValue % 3)
  let part = 0,
    firstNumber = 0,
    secondNumber = 0,
    rest = 0;
  let allValues = [firstNumber, secondNumber, rest];
  let remainder = startValue % 3;
  if (remainder === 0) {
    // devide evenly
    part = startValue / 3;
    allValues[0] = part;
    allValues[1] = part;
    allValues[2] = part;
    return allValues;
  } else if (remainder === 1) {
    part = (startValue - remainder) / 3;
    let rnd = randomIntFromInterval(0, 2);

    for (let i = 0; i < allValues.length; i++) {
      if (i === rnd) {
        allValues[i] = part + 1;
      } else {
        allValues[i] = part;
      }
    }
  } else {
    // if remainder is > 1
    part = (startValue - remainder) / 3;

    const possibleOrder = [
      [0, 1],
      [1, 2],
      [0, 2],
    ];

    const rnd = randomIntFromInterval(0, 2);
    const order = [...possibleOrder[rnd]];

    if (order.includes(0) && order.includes(1)) {
      console.log("contains 0 & 1: " + order);
      allValues[0] = part + 1;
      allValues[1] = part + 1;
      allValues[2] = part;
    }

    if (order.includes(1) && order.includes(2)) {
      console.log("contains 1 & 2: " + order);
      allValues[0] = part;
      allValues[1] = part + 1;
      allValues[2] = part + 1;
    }

    if (order.includes(0) && order.includes(2)) {
      console.log("contains 1 & 2: " + order);
      allValues[0] = part + 1;
      allValues[1] = part;
      allValues[2] = part + 1;
    }
  }

  return allValues;
}

export default {
  name: "RandomChords",
  props: {
    title: {
      type: String,
      default: "Random Chords",
    },
    description: {
      type: String,
      default: "set the total number of chords",
    },
  },
  data() {
    return {
      checked: false,
      number: 3,
      max: 24,
      allChords: [
        "C",
        "Cis",
        "D",
        "Dis",
        "E",
        "F",
        "Fis",
        "G",
        "Gis",
        "A",
        "Bes",
        "B",
      ],
      verseChords: [],
      chorusChords: [],
      bridgeChords: [],
    };
  },
  methods: {
    countDown() {
      if (this.number > 3) {
        this.number--;
      }
    },
    add() {
      if (this.number < this.max) this.number++;
    },
    generateChords() {
      // clean up any previous pushed chords
      this.verseChords = [];
      this.chorusChords = [];
      this.bridgeChords = [];
      let chordsArrays = [
        this.verseChords,
        this.chorusChords,
        this.bridgeChords,
      ];

      let numbers = splitInThree(this.number);
      let _self = this;

      // generate random numbers between 1 and 3
      const rndInt = randomIntFromInterval(0, 2);

      // fill each array with randomly generated chords
      chordsArrays.forEach(function (array, index) {
        //console.log(array, index)
        for (let i = 0; i < numbers[index]; i++) {
          array.push(
            _self.allChords[Math.floor(Math.random() * _self.allChords.length)]
          );
        }
      });
    },
    spunky(){
      console.log('spunky')
      this.verseChords = [];
      this.chorusChords = [];
      this.bridgeChords = [];
      let chordsArrays = [
        this.verseChords,
        this.chorusChords,
        this.bridgeChords,
      ];

      chordsArrays.forEach(arr => {
        const rndInt = randomIntFromInterval(1, 9);
        for(let i = 0; i < rndInt; i++){
          arr.push(this.allChords[Math.floor(Math.random() * this.allChords.length)]);
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

label {
  display: block;
  padding-bottom:12px;
}
.main {
  width: 375px;
  margin: 0 auto;
  height: 667px;
  background: rgb(85, 150, 207);
  color: white;
  h1 {
    font-weight: 400;
    font-size: 1.6em;
    padding: 8px;
  }
  span {
    display: block;
    font-weight: 200;
    padding-bottom: 8px;
  }
  .btn-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: normal;
    padding-bottom: 8px;
    button {
      display: block;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;
      align-self: auto;
      order: 0;
      width: 40px;
      height: 40px;
      margin-right: 4px;
      cursor: pointer;
    }
    .btn-left,
    .btn-right {
      background: white;
      border: none;
      outline: none;
      border-radius: 20px;
      font-size: 1.1rem;
      color: rgb(85, 150, 207);
      &:active {
        transform: scale(0.96);
      }
    }
    .btn-right {
      margin-right: 0;
      margin-left: 4px;
    }
    .number {
      display: block;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;
      align-self: auto;
      order: 0;
      width: 80px;
      height: 80px;
      line-height: 80px;
      cursor: pointer;
      background: white;
      color: rgb(85, 150, 207);
      font-size: 2rem;
      border-radius: 40px;
      font-weight: bold;
      transition: all ease-in-out 0.2s;
      user-select: none;
      &:active {
        transform: scale(0.96);
      }
    }
  }
}
.chords {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: normal;
  align-content: normal;
  &:last-child {
    padding-bottom:16px;
  }
}
.chord {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  padding:4px;
  background: white;
  color: rgb(85, 150, 207);
  margin-right:4px;
  font-size:1.5rem;
}
</style>
